import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  messages: string[] = [];
  constructor(
    public snackBar: MatSnackBar
  ) {}
  add(message: string): void {
    this.messages.push(message);
  }
  clear(): void {
    this.messages = [];
  }
  openSnackBar(message: string): void {
    this.snackBar.open(message, 'Dismiss', {
      duration: 3000,
    });
  }
}
