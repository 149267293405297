import {Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {share} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StorageService implements OnDestroy {
  private storage = new Subject<{ key: string, value: any }>();
  public changes = this.storage.asObservable().pipe(share());

  constructor() {
    this.start();
  }
  ngOnDestroy(): void {
    this.stop();
  }

  public get(inKey: string): any {
    return window.localStorage.getItem(inKey);
  }
  public set(inKey: string, inValue: any): void {
    window.localStorage.setItem(inKey, inValue);
    this.storage.next({ key: inKey, value: inValue });
  }
  public remove(inKey: string): void {
    window.localStorage.removeItem(inKey);
    this.storage.next({ key: inKey, value: null });
  }
  public clearAll(): void {
    window.localStorage.clear();
    this.storage.next({ key: null, value: null });
  }

  private start(): void {
    window.addEventListener('storage', this.storageEventListener.bind(this));
  }
  private stop(): void {
    window.removeEventListener('storage', this.storageEventListener.bind(this));
    this.storage.complete();
  }
  private storageEventListener(event: StorageEvent): void {
    if (event.storageArea === localStorage) {
      let newValue;
      try {
        newValue = JSON.parse(event.newValue);
      } catch (e) {
        newValue = event.newValue;
      }
      this.storage.next({ key: event.key, value: newValue });
    }
  }
}
