import {InitialFields} from '../../services/data/data-models';

export interface User {
  username: string;
  password: string;
  rights: Array<string>;
  characters: Array<InitialFields>;
  campaigns: Array<InitialFields>;
}
export const Users: Array<User> = [
  {
    username: 'giedrasas',
    password: 'giedrasas',
    rights: [],
    characters: [
      {
        id: 'd9429831-405c-4839-98b6-3061a04807a7',
        name: 'Barik Stormfoot'
      }
    ],
    campaigns: []
  },
  {
    username: 'toxic',
    password: 'toxic',
    rights: [],
    characters: [
      {
        id: '19fd01a7-9477-4fe0-8345-66cadb65b893',
        name: 'Helbrecht the Drifter'
      }
    ],
    campaigns: []
  },
  {
    username: 'dragomok',
    password: 'dragomok',
    rights: [],
    characters: [
      {
        id: '0fcbaa3e-d4c1-4265-a816-3052705fa873',
        name: 'Genovefa the Human Bear'
      }
    ],
    campaigns: []
  },
  {
    username: 'admin',
    password: 'dingus',
    rights: [
      'admin',
      'dungeon_master'
    ],
    characters: [
      {
        id: 'd9429831-405c-4839-98b6-3061a04807a7',
        name: 'Barik Stormfoot'
      },
      {
        id: '19fd01a7-9477-4fe0-8345-66cadb65b893',
        name: 'Helbrecht the Drifter'
      },
      {
        id: '0fcbaa3e-d4c1-4265-a816-3052705fa873',
        name: 'Genovefa the Human Bear'
      }
    ],
    campaigns: [
      {
        id: '4df07be1-25a9-4af2-9ea4-032543345999',
        name: 'Beginnings of Strife'
      }
    ]
  }
];
