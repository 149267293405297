<div class="d10-top-bar"></div>
<div class="d10-page">
  <div class="d10-login-wrapper">
    <table class="d10-login-title">
      <tr>
        <td rowspan="2"><fa-icon [icon]="title.icon" [fixedWidth]="true"></fa-icon></td>
        <td class="text-primary-light">THUNDERLANDS</td>
      </tr>
      <tr>
        <td class="nowrap text-light">{{title.text}}</td>
      </tr>
    </table>
    <mat-card (keydown.enter)="login()">
      <mat-form-field appearance="outline" class="max-width no-hint">
        <mat-label>Username</mat-label>
        <input matInput [(ngModel)]="username">
      </mat-form-field>
      <mat-form-field appearance="outline" class="max-width no-hint">
        <mat-label>Password</mat-label>
        <input matInput type="password" [(ngModel)]="password">
      </mat-form-field>
      <div class="d10-login-button">
        <button mat-stroked-button (click)="login()" class="button-accent">LOGIN</button>
      </div>
    </mat-card>
  </div>
</div>
