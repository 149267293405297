import {Injectable} from '@angular/core';
import {Checkbox, StateCheckbox} from './data/data-models';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() {}

  public static getViewType(inScreenWidth: number): string {
    if (inScreenWidth > 1740) {
      return 'desktop-wide';
    } else if (inScreenWidth > 1024) {
      return 'desktop';
    } else if (inScreenWidth > 768) {
      return 'tablet-wide';
    } else if (inScreenWidth > 480) {
      return 'tablet';
    }
    return 'mobile';
  }
  public static setPageSizeOptions(inDataList: Array<any>): number[] {
    let i = 0;
    const newArray = [10];
    inDataList.forEach( (element) => {
      i++;
      if (i === 20) {
        newArray.push(20);
      }
      if (i === 50) {
        newArray.push(50);
      }
      if (i === 100) {
        newArray.push(100);
      }
    });
    if (!newArray.includes(inDataList.length) && inDataList.length > 10) {
      newArray.push(inDataList.length);
    }
    return newArray;
  }
  public static newCheckboxList(inNumber: number): Array<Checkbox> {
    let i = 0;
    const newCheckboxArray: Array<Checkbox> = [];
    while (i < inNumber) {
      newCheckboxArray.push({checked: false});
      i++;
    }
    return newCheckboxArray;
  }
  public static newStateCheckboxList(inNumber: number): Array<StateCheckbox> {
    let i = 0;
    const newStateCheckboxArray: Array<StateCheckbox> = [];
    while (i < inNumber) {
      newStateCheckboxArray.push({checked: false, state: 0});
      i++;
    }
    return newStateCheckboxArray;
  }
  public static calculateList(inList: Array<Checkbox>, inLevel: number): void {
    inList.forEach( (listEntry, index) => {
      if (index + 1 <= inLevel) {
        listEntry.checked = true;
      }
    });
  }
  public static getNumberAsArray(inNumber: number): number[] {
    return Array.from(Array(inNumber).keys());
  }
}
