<div class="d10-top-bar"></div>
<div class="d10-page" [ngClass]="view">
  <div class="d10-menu">
    <button mat-icon-button class="d10-menu-button small"
            *ngIf="!view.startsWith('desktop')"
            (click)="menuExpanded = !menuExpanded"
            [ngClass]="menuExpanded ? 'expanded' : ''">
      <fa-stack>
        <fa-icon [icon]="Icons.faBars" [fixedWidth]="true" stackItemSize="1x" class="closed"></fa-icon>
        <fa-icon [icon]="Icons.faTimes" [fixedWidth]="true" stackItemSize="1x" class="open"></fa-icon>
      </fa-stack>
    </button>
    <table class="d10-menu-title" [routerLink]="'dashboard'">
      <tr>
        <td><fa-icon [icon]="title.icon" [fixedWidth]="true"></fa-icon></td>
        <td>{{title.text}}</td>
      </tr>
    </table>
    <mat-accordion [multi]="true" [ngClass]="menuExpanded ? 'expanded' : ''">
      <ng-container *ngFor="let menuItem of MenuItems">
        <mat-expansion-panel *ngIf="hasRights(menuItem.rights)"
                             #currentElement hideToggle class="nowrap"
                             [expanded]="isActiveMenu(menuItem.route)"
                             [ngClass]="isActiveMenu(menuItem.route) ? 'active' : ''"
                             (mouseenter)="currentElement.open()"
                             (mouseleave)="toggleMenuOnHover(currentElement, menuItem.route)">
          <mat-expansion-panel-header matRipple matRippleColor="rgba(255,255,255,0.06)" [routerLink]="menuItem.route" *ngIf="view.startsWith('desktop')">
            <fa-icon [icon]="menuItem.icon" [fixedWidth]="true"></fa-icon>
            <span class="left-spacer">{{menuItem.name}}</span>
          </mat-expansion-panel-header>
          <mat-expansion-panel-header matRipple matRippleColor="rgba(255,255,255,0.06)" [routerLink]="menuItem.route"
                                      (mouseenter)="currentElement.open()" *ngIf="!view.startsWith('desktop') && menuItem.link">
            <fa-icon [icon]="menuItem.icon" [fixedWidth]="true"></fa-icon>
            <span class="left-spacer">{{menuItem.name}}</span>
          </mat-expansion-panel-header>
          <mat-expansion-panel-header matRipple matRippleColor="rgba(255,255,255,0.06)" (click)="currentElement.open()"
                                      (mouseenter)="currentElement.open()" *ngIf="!view.startsWith('desktop') && !menuItem.link">
            <fa-icon [icon]="menuItem.icon" [fixedWidth]="true"></fa-icon>
            <span class="left-spacer">{{menuItem.name}}</span>
          </mat-expansion-panel-header>
          <ul *ngIf="menuItem.items.length > 0">
            <ng-container *ngFor="let subMenuItem of menuItem.items">
              <li *ngIf="hasRights(subMenuItem.rights)">
                <a [routerLink]="[menuItem.route + subMenuItem.route]"
                   [ngClass]="isActiveSubMenu(menuItem.route, subMenuItem.route) ? 'active' : ''"
                   (click)="!view.startsWith('desktop') ? menuExpanded = false : menuExpanded = true">
                  <fa-icon [icon]="subMenuItem.icon" [fixedWidth]="true"></fa-icon>
                  <span class="left-spacer">{{subMenuItem.name}}</span>
                </a>
              </li>
            </ng-container>
          </ul>
        </mat-expansion-panel>
      </ng-container>
      <mat-expansion-panel hideToggle class="nowrap" *ngIf="currentUser" [ngClass]="{'bottom-menu': view.startsWith('desktop')}">
        <mat-expansion-panel-header matRipple matRippleColor="rgba(255,255,255,0)">
          <fa-icon [icon]="Icons.faUserCircle" [fixedWidth]="true"></fa-icon>
          <span class="left-spacer">{{currentUser?.username | titlecase}}</span>
        </mat-expansion-panel-header>
        <ul>
          <li>
            <a [routerLink]="['login']">
              <fa-icon [icon]="Icons.faSignOutAlt" [fixedWidth]="true"></fa-icon>
              <span class="left-spacer">Log out</span>
            </a>
          </li>
        </ul>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div class="d10-content-wrapper" [ngClass]="menuExpanded ? 'expanded' : ''">
    <div class="d10-content-overlay" *ngIf="!view.startsWith('desktop') && menuExpanded"></div>
    <div class="d10-content" id="body-anchor" (scroll)="onScroll($event)">
      <div class="d10-content-width" [ngClass]="{'wide-screen' : view.startsWith('desktop-wide')}">
        <router-outlet (activate)="onActivate($event, outlet)" #outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
<app-loading></app-loading>
