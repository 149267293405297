import {fas} from '@fortawesome/free-solid-svg-icons';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

export interface MenuItem {
  route: string;
  name: string;
  icon: IconProp;
  rights: string[];
}
export interface PrimaryMenuItem extends MenuItem {
  items: Array<MenuItem>;
  link: boolean;
}

export const MenuItems: PrimaryMenuItem[] = [
  {
    route: 'game',
    name: 'Game',
    icon: fas.faCube,
    rights: [],
    link: true,
    items: [
      {
        route: '/attributes',
        name: 'Attributes',
        icon: fas.faLayerGroup,
        rights: []
      },
      {
        route: '/feats',
        name: 'Feats',
        icon: fas.faFire,
        rights: []
      },
      {
        route: '/professions',
        name: 'Professions',
        icon: fas.faEllipsisH,
        rights: []
      },
      {
        route: '/skills',
        name: 'Skills',
        icon: fas.faGripHorizontal,
        rights: []
      }
    ]
  },
  {
    route: 'collection',
    name: 'Collection',
    icon: fas.faScroll,
    rights: [],
    link: false,
    items: [
      {
        route: '/characters',
        name: 'Characters',
        icon: fas.faUsers,
        rights: []
      },
      {
        route: '/campaigns',
        name: 'Campaigns',
        icon: fas.faBook,
        rights: [
          'dungeon_master'
        ]
      }
    ]
  },
  {
    route: 'world',
    name: 'World',
    icon: fas.faMapMarked,
    rights: [
      'dungeon_master'
    ],
    link: true,
    items: []
  }
];
