import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MessageService} from '../../services/data/message.service';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {Users} from './users';
import {StorageService} from '../../services/storage/storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  username = '';
  password = '';
  Icons = fas;
  title = {
    text: 'Cracked Continent',
    icon: this.Icons.faDragon
  };
  users = Users;
  constructor(
    private router: Router,
    private message: MessageService,
    private storage: StorageService
  ) {}

  ngOnInit(): void {
    this.storage.clearAll();
  }
  login(): void {
    const loginUser = this.users.find(i => i.username === this.username && i.password === this.password);
    if (loginUser !== undefined) {
      this.storage.set('username', loginUser.username);
      this.storage.set('password', loginUser.password);
      this.router.navigate(['app/dashboard']);
    } else {
      this.message.openSnackBar('Invalid user!');
    }
  }

}
