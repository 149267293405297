import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {MenuItems} from './menu-items';
import {StorageService} from '../services/storage/storage.service';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {UtilityService} from '../services/utility.service';
import {fromEvent} from 'rxjs';
import {MatMenuTrigger} from '@angular/material/menu';
import {MatExpansionPanel} from '@angular/material/expansion';
import {User, Users} from './login/users';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html'
})
export class LayoutComponent implements OnInit {
  private currentUrl = '';
  public MenuItems = MenuItems;
  Icons = fas;
  title = {
    text: 'Cracked Continent',
    icon: this.Icons.faDragon
  };
  view: string;
  menuExpanded: boolean;
  loadingContent = false;
  users = Users;
  currentUser: User;

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger | undefined;
  constructor(
    private router: Router,
    private storageService: StorageService
  ) {
    router.events.subscribe((route: any) => {
      if (route.url === '/' || route.url === '/app') {
        this.currentUrl = '/app/dashboard';
      } else if (route.url !== undefined) {
        if (this.currentUrl.length > 0 && this.currentUrl !== route.url) {
          storageService.remove('currentCharacterId');
          if (route.url !== '/login') {
            const loginUser = this.users.find(i => i.username === storageService.get('username') && i.password === storageService.get('password'));
            if (loginUser === undefined) {
              router.navigate(['login']);
            }
          }
        }
        this.currentUrl = route.url;
      }
    });
    router.onSameUrlNavigation = 'reload';
    this.view = UtilityService.getViewType(document.body.offsetWidth);
    this.menuExpanded = this.view.startsWith('desktop');
  }

  ngOnInit(): void {
    this.currentUser = this.users.find(i => i.username === this.storageService.get('username') && i.password === this.storageService.get('password'));
    const screenSizeChanged$ = fromEvent(window, 'resize').subscribe(_ => {
      const previousView = this.view;
      this.view = UtilityService.getViewType(document.body.offsetWidth);
      this.menuExpanded = this.view.startsWith('desktop');
      if (!this.menuExpanded && previousView.startsWith('desktop')) {
        this.menuExpanded = false;
      }
    });
  }
  onScroll(inEvent: any): void {
    if (this.loadingContent) {
      inEvent.target.scrollTop = 0;
      this.loadingContent = false;
    }
  }
  onActivate(inEvent: any, outlet: HTMLElement): void {
    outlet.scrollTop = 0;
    this.loadingContent = true;
  }

  isActiveMenu(mainRoute: string): boolean {
    return this.router.url.split( '/' )[2] === mainRoute;
  }
  isActiveSubMenu(mainRoute: string, subRoute: string): boolean {
    if (this.isActiveMenu(mainRoute)) {
      return this.router.url.split( '/' )[3] === subRoute.replace( '/', '' );
    }
    return false;
  }
  toggleMenuOnHover(element: MatExpansionPanel, mainRoute: string): void {
    if (!this.isActiveMenu(mainRoute)) {
      element.close();
    } else {
      element.open();
    }
  }
  hasRights(inRights: string[]): boolean {
    let valid = true;
    inRights.forEach(right => {
      if (!this.currentUser.rights.includes(right)) {
        valid = false;
      }
    });
    return valid;
  }

}
