import {Component, ElementRef, ChangeDetectorRef, AfterViewInit, OnDestroy} from '@angular/core';
import {LoadingService} from '../../services/loading/loading.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements AfterViewInit, OnDestroy {
  loadingSubscription: Subscription;
  constructor(
    private loadingService: LoadingService,
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef
  ) {}
  ngAfterViewInit(): void {
    this.elementRef.nativeElement.style.display = 'none';
    this.loadingSubscription = this.loadingService.loading$.pipe().subscribe(
      (status: boolean) => {
        this.elementRef.nativeElement.style.display = status ? 'block' : 'none';
        this.changeDetectorRef.detectChanges();
      }
    );
  }
  ngOnDestroy(): void {
    this.loadingSubscription.unsubscribe();
  }
}
